import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from '@reach/router'
import routes from 'languages/pageTranslations/routes.json'
import logo from 'assets/images/logoWhite.svg';
import fb from 'assets/images/fb.svg';
import lin from 'assets/images/in.svg';
import certificate from 'assets/images/certificate.png';
import ModalBase from 'components/modals/modalBase';

import style from './footer.module.scss';
import useModal from "hooks/useModal";

const Footer = () => {
	const { messages, locale } = useIntl();
	const { isVisible, toggle } = useModal();
	const FooterNav = {
		col1: [
			{
				to: routes[locale]['personal-account'],
				title: messages['nav.account.personal']
			},
			{
				to: routes[locale]['business-account'],
				title: messages['nav.account.business']
			},
			{
				to: routes[locale]['card-solutions'],
				title: messages['nav.cards.full']
			}
		],
		col2: [
			{
				to: routes[locale]['legal'],
				title: messages['nav.term.pricing.legal']
			},
			{
				to: routes[locale]['pricing'],
				title: messages['nav.term.pricing.pricing']
			}
		],
		col3: [
			{
				to: routes[locale]['kyc-services'],
				title: messages['nav.services.kyc']
			},
			{
				to: routes[locale]['white-label-solutions'],
				title: messages['main.section4.title']
			}
		],
		col4: [
			{
				to: routes[locale]['about-us'],
				title: messages['about.title']
			},
			{
				to: routes[locale]['contact'],
				title: messages['button.contact']
			},
			{
				to: routes[locale]['faq'],
				title: messages['button.faq']
			}
		]
	};
	return (
		<div className={style.bgBlue}>
			<div className={classNames(style.container)}>
				<div className={style.flexLayout}>
					<Link to={routes[locale]['/']}>
						<img
							src={logo}
							className={style.logo}
							alt={messages['image.general.logoWhite']}
						/>
					</Link>
					<div className={style.menu}>
						<div className={style.menuItem}>
							<h6>{messages['nav.account.title']}</h6>
							{FooterNav.col1.map(link => (
								<Link key={link.title} to={link.to} className={style.link}>
									{link.title}
								</Link>
							))}
						</div>
						<div className={style.menuItem}>
							<h6>{messages['nav.term.pricing.title']}</h6>
							{FooterNav.col2.map(link => (
								<Link key={link.title} to={link.to} className={style.link}>
									{link.title}
								</Link>
							))}
						</div>
						<div className={style.menuItem}>
							<h6>{messages['nav.services.title']}</h6>
							{FooterNav.col3.map(link => (
								<Link key={link.title} to={link.to} className={style.link}>
									<span dangerouslySetInnerHTML={{ __html: link.title }} />
								</Link>
							))}
						</div>
						<div className={style.menuItem}>
							<h6>{messages['nav.term.company.title']}</h6>
							{FooterNav.col4.map(link => (
								<Link key={link.title} to={link.to} className={style.link}>
									{link.title}
								</Link>
							))}
						</div>
					</div>
					<div className={style.copyrights}>
						<img src={certificate} alt="" onClick={toggle} className={style.certificateIcon} />
					</div>
					<ModalBase isVisible={isVisible} hide={toggle}>
						<div>
							<h1 className={style.modalTitle}>
								<FormattedMessage id="certificate.title" defaultMessage="ISO/IEC 27001:2013 certification" />
							</h1>
							<p className={style.modalText}>
								<FormattedMessage
									id="certificate.text"
									defaultMessage="TÜV Thüringen e.V. Certification body has certified that UAB “Pervesk” applies an information security management system in line with the ISO/IEC 27001:2013 standard. {br}{br} Scope of the certification includes:{br} - Provision of electronic payment services to business and private customers;{br} - Provision of acquiring services;{br} - Issuance of payment means;{br} - Provision of White-Label Business Intelligence Platform;{br} - Provision of Know Your Customer and Anti Money Laundering services.{br}{br} ISO/IEC 27001:2013 is an international standard which lays down the requirements for establishing, implementing, maintaining, and continually improving information security management systems (ISMS). ISO/IEC 27001 certification helps organizations comply with legal, physical, and technical controls for the security of information. More information is available in the {link} and {link2}."
									values={{
										br: <br />,
										link: <a href={locale === "en" ? '/SERTIKA_2361_SERT_EN_27001_Pervesk.pdf' : '/SERTIKA_2361_SERT_LT_27001_Pervesk.pdf'} target="_blank" rel="noopener noreferrer"><b>{messages['certificate']}</b></a>,
										link2: <Link to={routes[locale]['information-security-policy']} onClick={toggle} ><b>{messages['certificate.link']}</b></Link>
									}}
								/>
							</p>
						</div>
					</ModalBase>
				</div>
				<hr className={style.breakLine} />
				<div className={style.bottomText}>
					<div className={style.copyrights}>
						<div className={style.socialMedia}>
							<a
								href={messages['facebook.link']}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={fb} alt={messages['image.general.fb']} />
							</a>
							<a
								href={messages['linkedin.link']}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={lin} alt={messages['image.general.in']} />
							</a>

						</div>
						<FormattedMessage
							id="copyrights.default"
							defaultMessage="© 2017 - {years} Pervesk, UAB"
							values={{
								years: new Date().getFullYear()
							}}
						/>
					</div>
					<br />
					<FormattedMessage
						id="footer.bottom.text"
						defaultMessage="Please note that the services described in this website are not offered in the United States and also in any other jurisdiction where such services could be considered restricted. If you choose to access this website, you do so at your own initiative and risk and are responsible for compliance with applicable laws, rules, and regulations that may apply to you."
					/>
				</div>
			</div>
		</div>
	);
};
export default Footer;
